<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card v-if="news">
                <v-card-title
                  >{{ news.title }}
                  <v-spacer></v-spacer>
                  <v-chip
                    v-if="newsIsNew(news.publishedAt.timestamp)"
                    label
                    color="red lighten-4"
                    class="font-weight-medium red--text text--darken-4"
                    >Neu</v-chip
                  ></v-card-title
                >
                <v-card-subtitle
                  ><v-icon small left>mdi-clock</v-icon
                  ><span>{{
                    timestampConverter(news.publishedAt.timestamp)
                  }}</span>
                  <v-icon small left class="ml-2">mdi-account-circle</v-icon
                  ><span>{{
                    news.publishedBy.displayName
                  }}</span></v-card-subtitle
                >
                <v-divider></v-divider>
                <div v-if="!disableHtmlPreview">
                  <shadow-component class="pa-4">
                    <v-card-text v-html="news.content.html"></v-card-text>
                  </shadow-component>
                  <v-card-text v-if="news.attachments">
                    <v-chip
                      v-for="(file, index) in news.attachments"
                      :key="index"
                      outlined
                      label
                      small
                      class="ma-1 font-weight-medium"
                      ><v-icon small left>{{ getFileIcon(file.type) }}</v-icon
                      >{{ file.filename }}</v-chip
                    >
                  </v-card-text>
                </div>
                <v-card-text v-else>
                  <v-alert type="error" text icon="mdi-alert" class="mb-6">
                    <div class="title">
                      Darstellung von Inhalten deaktiviert
                    </div>
                    <div class="mt-2">
                      Aus Sicherheitsgründen wurde diese Funktion deaktiviert.
                      Kontaktiere den Support, um die Darstellung für diese
                      Organisation wieder zu aktivieren.
                    </div>
                  </v-alert>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { DELETE_NEWS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import ShadowComponent from "@/components/_systemwide/ShadowComponent.vue";
import {
  MY_ORGANIZATION_NEWS_update,
  MY_ORGANIZATION_NEWS_delete,
} from "@/data/permission-types.js";

export default {
  name: "my-organization-news-details",
  components: {
    NavigationBar,
    SupportTools,
    ShadowComponent,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permission: `${MY_ORGANIZATION_NEWS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "my-organization-news-edit",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },
        {
          title: "Löschen",
          permission: `${MY_ORGANIZATION_NEWS_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      news: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "news", data: this.news }];
    },
    disableHtmlPreview() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "disableHtmlPreview"
      );
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("news")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.news = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    newsIsNew(publishTimestamp) {
      const publishDate = publishTimestamp.toDate();
      const now = new Date();
      const diff = Math.abs(publishDate.getTime() - now.getTime());
      const diffInHours = diff / (1000 * 3600);
      return diffInHours < 48;
    },
    getFileIcon(fileType) {
      let [generalType, specificType] = fileType.split("/");
      switch (generalType) {
        case "application":
          switch (specificType) {
            case "pdf":
              return "mdi-file-pdf";
            case "msword":
            case "vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "mdi-file-word";
            case "vnd.ms-excel":
            case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "mdi-file-excel";
            case "vnd.ms-powerpoint":
            case "vnd.openxmlformats-officedocument.presentationml.presentation":
              return "mdi-file-powerpoint";
            case "zip":
            case "x-rar-compressed":
            case "x-7z-compressed":
              return "mdi-folder-zip";
            case "plain":
              return "mdi-file-document";
            default:
              return "mdi-file";
          }
        case "image":
          return "mdi-file-image";
        case "audio":
          return "mdi-file-music";
        case "video":
          return "mdi-file-video";
        default:
          return "mdi-file";
      }
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du die Neuigkeit löschen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${MY_ORGANIZATION}${DELETE_NEWS}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style>
blockquote {
  padding-left: 1rem;
  border-left: 4px solid rgba(242, 242, 242);
}
</style>
